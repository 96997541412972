



import { VeeValidateConfig } from 'vee-validate/dist/types/config';
import { Component, Vue } from 'vue-property-decorator';
import Footer from '../components/layouts/Footer.vue';
import Header from '../components/layouts/Header.vue';
import TopCourseList from '../components/contents/course/TopCourseList.vue';
import InfoBanner from '../components/contents/info/InfoBanner.vue';
import InfoListTag from '../components/contents/info/InfoListTag.vue';
import VueRouter from 'vue-router';
import axios, {AxiosResponse, AxiosError} from 'axios';
import { WPPost, WPCategories, CourseResponseCourses } from '../../types/typescript-axios/api';
import VeeValidate from '../plugins/vee-validate';
import { LoginRequest } from '../../types/typescript-axios/api';
import store from '../store';
import { sortCourseList } from '../plugins/helper';
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
]);

async function getDatas(to: VueRouter) {
  let status: number = 200;
  const bannerMaxLength: number = 4;
  const infoMaxLength: number = 5;
  const infoUrl: string = '/wp-json/moc/info?order=desc&orderby=date&page=1&per_page=' + infoMaxLength;
  const bannerUrl: string = '/wp-json/wp/v2/banner?per_page=' + bannerMaxLength;
  const categoryUrl: string = '/wp-json/wp/v2/info_cat';
  const courseUrl: string = '/wp-json/moc/course';

  let bannerRes;
  let categoryRes;
  let courseData;
  let infoData;
  try {
    [bannerRes, categoryRes, courseData, infoData] = await Promise.all([
      axios.get<AxiosResponse>(bannerUrl),
      axios.get<AxiosResponse>(categoryUrl),
      axios.get<AxiosResponse>(courseUrl, {
        params: {
          toppage: 1,
          orderby: 'event_date',
          order: 'desc',
        },
      }),
      axios.get<AxiosResponse>(infoUrl),

      // axios.get('http://.../get1').catch(e => { throw 'get1 error '+e.message}),
      // axios.get('http://.../get2').catch(e => { throw 'get2 error '+e.message}),
      // axios.get('http://.../get3').catch(e => { throw 'get3 error '+e.message}),
    ]);
  } catch (err: any) {
    console.log(err);
    return {
      status: 500,
    };
  }


  const banners: any = bannerRes.data;
  const categoriesRes: any = categoryRes.data;
  const $to: any = to;

  const aryCheck = categoriesRes.filter((value: any) => {
    if (value.slug === $to.query.category && value.count > 0 && value.parent === 0) {
      return true;
    }
  });
  const categories = categoriesRes.filter((value: any) => {
    if (value.count > 0 && value.parent === 0) {
      return true;
    }
  });

  let queryName: string = '';

  if (typeof $to.query.category !== 'undefined') {
    if (aryCheck.length <= 0) {
      return {
        status: 404,
      };
    } else {
      queryName = $to.query.category;
    }
  }


  status = 200;

  return {
    status,
    infoData,
    courseData,
    categories,
    banners,
    queryName,
    $to,
  };
}

@Component({
  mixins: [ VeeValidate ],
  components: {
    Footer,
    Header,
    TopCourseList,
    InfoBanner,
    InfoListTag,
  },
})
export default class Home extends Vue {
  get isLogin() {
    return this.$store.getters['auth/isLogin'];
  }
  get infoUrl(): string {
    return process.env.VUE_APP_PATH_INFO;
  }
  get signupUrl(): string {
    return process.env.VUE_APP_PATH_SIGNUP;
  }
  get loginUrl(): string {
    return process.env.VUE_APP_PATH_LOGIN;
  }
  get loginForgetPasswordUrl(): string {
    return process.env.VUE_APP_PATH_LOGIN_FORGET_PASSWORD;
  }
  get loginRedirect(): string {
    const query: any = this.$route.query;
    if (typeof query.redirect !== 'undefined') {
      return String(this.$route.query.redirect);
    } else {
      return '/';
    }
  }

  private course: CourseResponseCourses[] = [];

  get slideCourse(): CourseResponseCourses[] {
    // return this.course;
    return sortCourseList(this.course);
  }

  private paramsName: number = 0;
  private link: string = '/info/?id=';
  private infoContents: WPPost[] = [];
  private banners: WPPost[] = [];
  private categories: WPCategories[] = [];
  private status = 200;
  private queryName: string = '';
  private categoryId: number = -1;
  private mail: string = '';
  private password: string = '';
  private errorMessage: boolean = false;
  private errorCode: number|null = null;
  // private notFound: boolean = false;

  private async login() {
    const $observer: any = this.$refs.observer;
    $observer.reset();
    $observer.validate().then(async (result: boolean) => {
      if (result) {
        const param: LoginRequest = {
          password: this.password,
          username: this.mail,
        };
        try {
          await store.dispatch('auth/loginAuth', param);
        } catch (error: any) {
          if (error.status === 401 && error.data.errors.code === 10) {
            this.$router.push({ path: '/login/error/' });
          } else {
            this.errorMessage = true;
            this.status = error.status;
            this.errorCode = error.data.errors.code;
          }
        }
      } else {
        this.errorMessage = true;
        window.scrollTo({
          // top: this.contentPosition.top,
          top: 0,
          behavior: 'smooth',
        });
      }
    });
  }
  private async beforeRouteEnter(to: VueRouter, from: VueRouter, next: any) {
    const data: any = await getDatas(to);
    if (data.status === 200) {
      next((vm: {
        infoContents: WPPost,
        course: CourseResponseCourses,
        banners: WPPost,
        categories: WPCategories,
        queryName: string,
        paramsName: number,
        categoryId: number,
        status: number,
      }) => {
        vm.status = data.status;
        vm.infoContents = data.infoData.data;
        vm.course = data.courseData.data.courses;
        vm.banners = data.banners;
        vm.categories = data.categories;
        vm.queryName = data.queryName;
        vm.paramsName = Number(data.$to.params.p);
        vm.categoryId = data.categoryId;
      });
    } else {
      next((vm: {status: number}) => {
        vm.status = data.status;
      });
    }
  }
  private async beforeRouteUpdate(to: VueRouter, from: VueRouter, next: any) {
    const data: any = await getDatas(to);
    this.status = data.status;
    if (data.status === 200) {
      const $to: any = to;
      this.paramsName = Number($to.params.p);
      this.infoContents = data.infoData.data;
      this.course = data.courseData.data.courses;
      this.categories = data.categories;
      this.queryName = data.queryName;
      this.categoryId = data.categoryId;
    }
    next();
  }

}
