

















import { Component, Vue, Prop } from 'vue-property-decorator';
import { courseTimeStatus, mansekiStatus, isReservedStatus, isWaitingList, isCourseApplicationMethod } from '../../../plugins/helper';
import { CourseResponseCourses } from '../../../../types/typescript-axios/api';
@Component({
})
export default class LabelApplicationMethodStatus extends Vue {
  @Prop({default: {}})
  private course!: CourseResponseCourses;

  private get isReserveStatus(): number {
    const isCourseMethod: number = isCourseApplicationMethod(this.course);
    return isCourseMethod;
  }
}
