import { render, staticRenderFns } from "./TopCourseList.vue?vue&type=template&id=48a2f513&scoped=true&"
import script from "./TopCourseList.vue?vue&type=script&lang=ts&"
export * from "./TopCourseList.vue?vue&type=script&lang=ts&"
import style0 from "./TopCourseList.vue?vue&type=style&index=0&id=48a2f513&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48a2f513",
  null
  
)

export default component.exports