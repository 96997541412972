























import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import Swiper, { SwiperOptions } from 'swiper';
import CourseCard from './CourseCard.vue';
import { WPPost } from '../../../../types/typescript-axios/api';
// 参考サイト
// https://insider.10bace.com/2019/02/06/vue-js%E3%81%A8swiper%E3%81%A8%E6%99%82%E3%80%85axios/

const swiperOptions: SwiperOptions = {
  loop: true,
  slidesPerView: 3,
  spaceBetween: 15,
  loopedSlides: 6, // NOTE poster数÷2 以上
  speed: 700,
  centeredSlides: true,
  grabCursor: true,
  pagination: {
    el: '.swiper-pagination',
  },
  navigation: {
    nextEl: '.top-course-list-next',
    prevEl: '.top-course-list-prev',
  },
  breakpoints: {
    1024: {
      spaceBetween: 40,
      centeredSlides: false,
    },
  },
};

@Component({
  components: {
    CourseCard,
  },
})
export default class TopCourseList extends Vue {
  // private breakpoint: number = 767;
  // private mySwiperBool: boolean = false;
  @Prop({default: () => []})
  private slides!: WPPost[];

  private slidesCount: number = 0;
  private mySwiper!: Swiper;

  @Prop({default: ''})
  private swiperInitKey!: string;

  get swiperKey(): string {
    return `swiper-key-${this.swiperInitKey}`;
  }

  @Emit()
  private createSwiper() {
    const $ref: any = this.$refs;
    if (this.slides.length !== this.slidesCount) {
      if (typeof $ref.swiper !== 'undefined') {
        this.mySwiper = new Swiper($ref.swiper, swiperOptions);
        // this.slidesCount = this.slides.length;
      }
    }
  }
  @Emit()
  private prev() {
    this.mySwiper.slidePrev();
  }
  @Emit()
  private next() {
    this.mySwiper.slideNext();
  }

  private mounted(): void {
    this.$nextTick().then(() => {
      const $ref: any = this.$refs;
      this.createSwiper();
    });
  }
}
